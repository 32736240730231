<template>
	<BannerTextForm
		:is-loading="isLoading"
		:banner-type="bannerType"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BannerTextForm from '@/components/BannerTextForm.vue';

import { BANNER_TYPE } from '../enums/banners';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'BannerTextCreate',

	components: {
		BannerTextForm,
	},

	props: {
		bannerType: {
			type: String,
			default: BANNER_TYPE.text,
		},
	},

	computed: {
		...mapState('banners', {
			create: 'create',
		}),

		isLoading() {
			return this.create.isLoading;
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			createBannerGroup: 'banners/createBannerGroup',
		}),

		async handleSubmit(data) {
			try {
				await this.createBannerGroup(data);

				this.$router.push({ name: 'BannerTextList' });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
